import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useReadContract } from 'wagmi';
import { useDistributorApiClient } from '../client/distributor-api-client';
import { useAuth } from '../context/auth/auth-context';
import { useWallet } from '../context/wallet/wallet-context';
import { useAsync } from '../hook/async';

export const useGetContracts = () => {
  const client = useDistributorApiClient();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: any) => {
      return client(
        `contracts/search`,
        {
          method: 'post',
          data,
        },
      );
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: ['contracts'] }),
  });
};

export const useGetContract = (contractId: string) => {
  const {
    user: { walletAddress },
  } = useAuth();
  const { connectedChainId } = useWallet();
  const client = useDistributorApiClient();
  const { run, data } = useAsync();
  const [contract, setContract] = useState(null);
  const [loading, setLoading] = useState(true);
  const [reload, setReload] = useState(null);

  useEffect(() => {
    if (!reload) {
      return;
    }

    run(
      client(
        `contracts/find`,
        {
          method: 'post',
          data: {
            contractId: `${contractId}-${connectedChainId}`,
            walletAddress: walletAddress,
            includeHidden: false,
          },
        },
      ),
    );
  }, [reload]);

  useEffect(() => {
    if (!data) {
      return;
    }

    if (data?.contract) {
      //const formattedDistributor = formatDistributor(data.distributor);
      //ask Aaron about this
      setContract(data.contract);
    }

    setLoading(false);
  }, [data]);

  const refresh = () => {
    setContract(null);
    setLoading(true);
    setReload(new Date());
  };

  useEffect(() => {
    if (walletAddress) {
      refresh();
    }
  }, [walletAddress]);

  return { contract: contract, loading: loading, refresh: refresh };
};

export const useFindContract = () => {
  const client = useDistributorApiClient();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: any) => {
      return client(
        `contracts/find`,
        {
          method: 'post',
          data,
        },
      );
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: ['delegates'] }),
  });
};

export const useGetContractOwner = (
  address: `0x${string}`,
  chainId: number,
  abi: any,
) => {
  const { data: owner, ...rest } = useReadContract({
    address,
    chainId,
    abi,
    functionName: 'owner',
  });

  return {
    owner,
    ...rest,
  };
};
