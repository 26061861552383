import * as Sentry from '@sentry/react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { ethers } from 'ethers';
import { useEffect, useState } from 'react';
import web3 from 'web3';
import { useEventApiClient } from '../client/event-api-client';
import { useAccount } from '../context/account/account-context';
import { useAuth } from '../context/auth/auth-context';
import { useConfiguration } from '../context/configuration/configuration-context';
import { useNetworks } from '../context/network/network-context';
import { useToast } from '../context/toast/toast-context';
import { useWagmi } from '../context/wagmi/wagmi-context';
import { useAsync } from '../hook/async';
import { SALE_STATUS } from '../type/sale-status';
import {
  allowedRegionsToCountryList,
  blockedRegionsFromCountryList,
} from '../util/country';
import { unixToLocalDateTime } from '../util/date-time';
import { gt, min, mult, sub } from '../util/number';
import { uniqueValues } from '../util/object';
import { getFlatPriceSaleABI } from './../../../payment/src/utils/abi';

export const useValidateSaleParticipants = () => {
  const client = useEventApiClient();

  return useMutation({
    mutationFn: (data: any) => {
      return client(`sales/participants/validate`, {
        method: 'post',
        data,
      });
    },
  });
};

export const usePrepareSaleParticipantsUpdate = () => {
  const client = useEventApiClient();

  return useMutation({
    mutationFn: (data: any) => {
      return client(`sales/prepare/participants`, {
        method: 'post',
        data,
      });
    },
  });
};

export const usePrepareSale = () => {
  const client = useEventApiClient();

  return useMutation({
    mutationFn: (data: any) => {
      return client(`sales/prepare`, {
        method: 'post',
        data,
      });
    },
  });
};

export const useSaleDeployed = () => {
  const client = useEventApiClient();

  return useMutation({
    mutationFn: (data: any) => {
      return client(`sales/saleDeployed`, {
        method: 'post',
        data,
      });
    },
  });
};

export const useSaleConfigUpdated = () => {
  const client = useEventApiClient();

  return useMutation({
    mutationFn: (data: any) => {
      return client(`sales/saleConfigUpdated`, {
        method: 'post',
        data,
      });
    },
  });
};

export const useGetSaleParticipantsSummary = (saleId?: string) => {
  const client = useEventApiClient();

  return useQuery({
    enabled: !!saleId,
    queryKey: ['sales/participants', saleId],
    queryFn: () => client(`sales/${saleId}/participants/summary`),
  });
};

export const useGetSale = (isOwner: boolean = false, eventId: string) => {
  const { account } = useAccount();
  const {
    user: { walletAddress },
  } = useAuth();
  const { supportedNetworks } = useNetworks();
  const client = useEventApiClient();
  const { run, data } = useAsync();
  const [sale, setSale] = useState(null);
  const [loading, setLoading] = useState(true);
  const [reload, setReload] = useState(null);

  useEffect(() => {
    if (!reload) {
      return;
    }

    const supportedNetworkChains = supportedNetworks.map((network) => {
      return { chainId: network.id };
    });

    run(
      client(`sales/find`, {
        method: 'post',
        data: {
          eventId,
          chains: supportedNetworkChains,
          walletAddress: walletAddress.toLowerCase(),
          isOwner: isOwner,
          includeIpfsConfig: true,
        },
      }),
    );
  }, [reload]);

  useEffect(() => {
    if (walletAddress) {
      refresh();
    }
  }, [walletAddress]);

  useEffect(() => {
    if (!data) {
      return;
    }

    if (data?.sale) {
      const formattedSale = formatSale(data.sale, walletAddress, account);
      setSale(formattedSale);
      setLoading(false);
    } else {
      console.log('No sale records returned', data);
      setLoading(false);
    }
  }, [data]);

  const refresh = () => {
    setSale(null);
    setLoading(true);
    setReload(new Date());
  };

  return { sale: sale, loading: loading, refresh: refresh };
};

export const useGetSaleContractOwner = (sale: {
  id: string;
  chainId: number;
}) => {
  const wagmi = useWagmi();
  const [owner, setOwner] = useState(null);
  const { showErrorToast } = useToast();

  useEffect(() => {
    (async () => {
      if (sale) {
        const saleChain = wagmi.activeChains.find(
          (chain) => chain.id === sale.chainId,
        );
        const provider = new ethers.providers.JsonRpcProvider(
          saleChain.rpcUrls.default.http[0],
        );
        const contract = new ethers.Contract(
          sale.id,
          getFlatPriceSaleABI(),
          provider,
        );
        try {
          const owner = await contract.owner();
          setOwner(owner);
        } catch (e) {
          showErrorToast({
            description: 'Error fetching sale contract owner.',
          });
          Sentry.captureException(e);
        }
      }
    })();
  }, [sale]);

  return owner;
};

export const useGetSales = () => {
  const { account } = useAccount();
  const {
    user: { walletAddress },
  } = useAuth();
  const { supportedNetworks } = useNetworks();
  const client = useEventApiClient();
  const { configuration } = useConfiguration();
  const { run, data } = useAsync();
  const [searchResults, setSearchResults] = useState([]);
  const [moreResults, setMoreResults] = useState(null);
  const [loadingComplete, setLoadingComplete] = useState([]);
  const [reload, setReload] = useState(null);

  const PAGE_SIZE: number = 10;

  /**
   * Build API request
   */
  const getClient = (request: any) => {
    let url = 'sales/account/search';

    if (request.nextLink) {
      url += '?' + request.nextLink.split('?')[1];
    } else {
      url += `?offset=0&queryLimit=${PAGE_SIZE}`;
    }

    return client(url, {
      method: 'post',
      data: request.searchRequest,
    });
  };

  /**
   * Iterate over each network and execute query
   */
  useEffect(() => {
    if (!reload) {
      return;
    }

    for (let i = 0; i < supportedNetworks.length; i++) {
      const network = supportedNetworks[i];
      console.log('Fetching sales for network:', network.name);

      /* Setup default query parameters */
      run(
        getClient({
          searchRequest: {
            chainId: network.chainId,
            walletAddress: walletAddress.toLowerCase(),
            projectId: configuration?.project?.id,
            includeIpfsConfig: true,
          },
        }),
      );
    }
  }, [reload]);

  /**
   * Accumulate data and trigger fetching of additional pages (if available)
   */
  useEffect(() => {
    if (!data) {
      return;
    }

    if (data?.sales?.length > 0) {
      console.log('Adding new sales search results', data);

      const formattedSales = formatSales(data, walletAddress, account);
      setSearchResults(uniqueValues(searchResults.concat(formattedSales)));

      if (data.nextLink) {
        setMoreResults(data);
      } else {
        setLoadingComplete(
          loadingComplete.concat([data.searchRequest.chainId]),
        );
      }
    } else {
      setLoadingComplete(loadingComplete.concat([data.searchRequest.chainId]));
    }
  }, [data]);

  /**
   * Fetch additional page of data
   */
  useEffect(() => {
    if (moreResults) {
      console.log('Fetching more sales results:', moreResults);
      run(getClient(moreResults));
    }
  }, [moreResults]);

  const refresh = () => {
    setSearchResults([]);
    setMoreResults(null);
    setLoadingComplete([]);
    setReload(new Date());
  };

  useEffect(() => {
    if (configuration && walletAddress) {
      refresh();
    }
  }, [configuration, walletAddress]);

  return {
    results: searchResults,
    loading: loadingComplete.length !== supportedNetworks.length,
    refresh: refresh,
  };
};

const formatSale = (sale: any, walletAddress: string, account?: any) => {
  const formattedSale: any = {
    ...sale,
  };

  const allowedRegions = allowedRegionsToCountryList(
    sale.event?.allowedRegions,
  );
  const blockedRegions = blockedRegionsFromCountryList(allowedRegions);

  formattedSale.restrictedCities = sale.restrictedCities;
  formattedSale.blockedRegions = blockedRegions;
  formattedSale.decimals = sale.decimals || '18';
  formattedSale.isOwner =
    web3.utils.toChecksumAddress(sale.owner.id) ===
    web3.utils.toChecksumAddress(walletAddress);
  formattedSale.status = setSaleStatus(sale);
  formattedSale.saleOpenDate = unixToLocalDateTime(
    sale.startTime,
    account?.timezone,
  );
  formattedSale.saleCloseDate = unixToLocalDateTime(
    sale.endTime,
    account?.timezone,
  );
  formattedSale.validSale = true;
  formattedSale.purchaseDocumentsGenerated = false;
  formattedSale.logo = Array.isArray(sale.logo) ? null : sale.logo;
  formattedSale.favicon = Array.isArray(sale.favicon) ? null : sale.favicon;
  formattedSale.proof = sale.authorization?.proof || sale.proof;

  return formattedSale;
};

const formatSales = (data: any, walletAddress: string, account?: any) => {
  return data?.sales.map((sale) => {
    return formatSale(sale, walletAddress, account);
  });
};

const setSaleStatus = (sale: any): SALE_STATUS => {
  const { startTime, endTime, purchaseTotal, saleMaximum, userMaximum } = sale;
  let status = SALE_STATUS.COMPLETED;
  const now = dayjs();

  if (!startTime && !endTime) {
    return SALE_STATUS.UPCOMING;
  }

  if (now.isBefore(dayjs.unix(parseInt(startTime)))) {
    status = SALE_STATUS.UPCOMING;
  } else {
    status = SALE_STATUS.CURRENT;
    if (now.isAfter(dayjs.unix(parseInt(endTime)))) {
      status = SALE_STATUS.COMPLETED;
    }
  }

  if (isSaleCapMet(purchaseTotal, saleMaximum, userMaximum)) {
    status = SALE_STATUS.COMPLETED;
  }

  return status;
};

const isSaleCapMet = (
  purchaseTotal: string,
  saleMaximum: string,
  userMaximum: string,
) => {
  const limit = sub(
    saleMaximum,
    min(mult(5, userMaximum), mult(0.005, saleMaximum)),
  );
  return gt(purchaseTotal, limit);
};

export const useGetSalesPaymentsMethodBalance = () => {
  const client = useEventApiClient();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: any) => {
      return client(`sales/paymentMethodsBalance`, {
        method: 'post',
        data,
      });
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: ['sale'] }),
  });
};
