import {
  Button,
  Col,
  FrontArrowIcon,
  InputGroup,
  Row,
  Section,
  SectionTitle,
  Stacked,
  TableData,
  Text,
} from '@tokensoft-web/common-ui';
import {
  GENESIS_ADDRESS,
  getTruncatedAddress,
  useClipboard,
  useNetworks,
  useToast,
} from '@tokensoft-web/common-utils';
import { getTxUrl } from '@tokensoft-web/common-utils/src/util/network';
import { ethers } from 'ethers';
import { useEffect, useState } from 'react';
import { AiOutlineCopy, AiOutlineLoading3Quarters } from 'react-icons/ai';
import { VscLinkExternal } from 'react-icons/vsc';
import { useUpdateOwner, useUpdateSaleOnChainConfig } from '../../utils/sale';

interface SaleOwnerProps {
  context: any;
  setContext?: Function;
}

const SaleOwner = ({ context, setContext }: SaleOwnerProps) => {
  const { copyToClipboard } = useClipboard();
  const { showErrorToast, showSuccessToast } = useToast();
  const { getNetworkDetails } = useNetworks();
  const [owner, setOwner] = useState();
  const [recipient, setRecipient] = useState();
  const [ownerUpdatedAt, setOwnerUpdatedAt] = useState(null);
  const [recipientUpdatedAt, setRecipientUpdatedAt] = useState(null);

  const validRecipient =
    !recipient ||
    (ethers.utils.isAddress(recipient) && recipient !== GENESIS_ADDRESS);
  const recipientError = validRecipient ? null : 'Invalid address';

  const validOwner =
    !owner || (ethers.utils.isAddress(owner) && owner !== GENESIS_ADDRESS);
  const ownerError = validOwner ? null : 'Invalid address';

  const {
    error: updateOwnerError,
    write: updateOwner,
    isLoading: updateOwnerLoading,
    data: updateOwnerReceipt,
  } = useUpdateOwner();

  const {
    error: updateConfigError,
    write: updateConfig,
    isLoading: updateConfigLoading,
    data: updateConfigReceipt,
  } = useUpdateSaleOnChainConfig();

  const saving = updateOwnerLoading || updateConfigLoading;

  const key = 'sale-owner';

  useEffect(() => {
    if (!ownerUpdatedAt) {
      return;
    }

    setContext({
      ...context,
      owner: owner,
    });
  }, [ownerUpdatedAt]);

  useEffect(() => {
    if (!recipientUpdatedAt) {
      return;
    }

    setContext({
      ...context,
      recipient: recipient,
    });
  }, [recipientUpdatedAt]);

  const saveOwner = async () => {
    try {
      await updateOwner(context.chainId, context.id, [owner]);
    } catch (e) {
      showErrorToast({ description: e.message });
      console.error(e);
    }
  };

  const saveRecipient = async () => {
    try {
      const newOnChainConfig = {
        recipient: recipient,
        merkleRoot: context.merkleRoot,
        price: context.price,
        saleMaximum: context.saleMaximum,
        userMaximum: context.userMaximum,
        purchaseMinimum: context.purchaseMinimum,
        startTime: context.startTime,
        endTime: context.endTime,
        maxQueueTime: context.maxQueueTime,
        URI: context.uris[0],
      };

      await updateConfig(context.chainId, context.id, [newOnChainConfig]);
    } catch (e) {
      showErrorToast({ description: e.message });
      console.error(e);
    }
  };

  useEffect(() => {
    if (updateOwnerReceipt) {
      if (updateOwnerReceipt.status === 'success') {
        setOwnerUpdatedAt(new Date());
        showSuccessToast({
          description: (
            <div className='flex flex-row'>
              Successfully submitted transaction.
              <a
                target='_blank'
                rel='noreferrer'
                href={getTxUrl(
                  updateOwnerReceipt.transactionHash,
                  getNetworkDetails(context.chainId),
                )}
                className='w-[30px] flex items-center justify-center text-white'
                onClick={(e) => e.stopPropagation()}
              >
                <VscLinkExternal color='white' />
              </a>
            </div>
          ),
        });
      }
    }
  }, [updateOwnerReceipt]);

  useEffect(() => {
    if (updateOwnerError) {
      showErrorToast({ description: updateOwnerError.toString() });
    }
  }, [updateOwnerError]);

  useEffect(() => {
    if (updateConfigReceipt) {
      if (updateConfigReceipt.status === 'success') {
        setRecipientUpdatedAt(new Date());
        showSuccessToast({
          description: (
            <div className='flex flex-row'>
              Successfully submitted transaction.
              <a
                target='_blank'
                rel='noreferrer'
                href={getTxUrl(
                  updateConfigReceipt.transactionHash,
                  getNetworkDetails(context.chainId),
                )}
                className='w-[30px] flex items-center justify-center text-white'
                onClick={(e) => e.stopPropagation()}
              >
                <VscLinkExternal color='white' />
              </a>
            </div>
          ),
        });
      }
    }
  }, [updateConfigReceipt]);

  useEffect(() => {
    if (updateConfigError) {
      showErrorToast({ description: updateConfigError.toString() });
    }
  }, [updateConfigError]);

  return (
    <Stacked data-testid={`${key}`}>
      <Section xgap={5} ygap={5}>
        <Col>
          <Row nowrap xalign={'between'}>
            <SectionTitle width={'1/2'}>Current Owner</SectionTitle>
            <Row width={'1/2'} nowrap gap={2} yalign={'center'} xalign={'end'}>
              <div
                className='text-primary-medium cursor-pointer'
                onClick={() => copyToClipboard(context?.owner)}
              >
                <AiOutlineCopy size={16} />
              </div>
              <TableData
                className='break-anywhere'
                data-testid={`${key}-owner-data`}
              >
                {getTruncatedAddress(context?.owner)}
              </TableData>
            </Row>
          </Row>

          <Text>
            Please add wallet address below to select a new owner and overwrite
            the existing one.
          </Text>
        </Col>

        <Col>
          <Row>
            <InputGroup
              name={'owner'}
              placeholder={'Enter Address'}
              required={true}
              value={owner}
              onChange={(changed) => {
                setOwner(changed.target.value);
              }}
              valid={validOwner}
              error={ownerError}
            />
          </Row>

          <Button
            className='w-fit'
            size={'md'}
            disabled={!owner || saving}
            onClick={() => saveOwner()}
          >
            {updateConfigLoading ? (
              <>
                <div className='animate-spin'>
                  <AiOutlineLoading3Quarters size={18} />
                </div>
                <Text>Saving...</Text>
              </>
            ) : (
              <>
                <Text>Save</Text>
                <FrontArrowIcon />
              </>
            )}
          </Button>
        </Col>
      </Section>

      <Section xgap={5} ygap={5}>
        <Col>
          <Row nowrap xalign={'between'}>
            <SectionTitle width={'1/2'}>Current Recipient</SectionTitle>
            <Row width={'1/2'} nowrap gap={2} yalign={'center'} xalign={'end'}>
              <div
                className='text-primary-medium cursor-pointer'
                onClick={() => copyToClipboard(context?.recipient)}
              >
                <AiOutlineCopy size={16} />
              </div>
              <TableData
                className='break-anywhere'
                data-testid={`${key}-recipient-data`}
              >
                {getTruncatedAddress(context?.recipient)}
              </TableData>
            </Row>
          </Row>

          <Text>
            Please add wallet address below to select a new recipient and
            overwrite the existing one.
          </Text>
        </Col>

        <Col>
          <Row>
            <InputGroup
              name={'recipient'}
              placeholder={'Enter Address'}
              required={true}
              value={recipient}
              onChange={(changed) => {
                setRecipient(changed.target.value);
              }}
              valid={validRecipient}
              error={recipientError}
            />
          </Row>

          <Button
            className='w-fit'
            size={'md'}
            disabled={!recipient || saving}
            onClick={() => saveRecipient()}
          >
            {updateConfigLoading ? (
              <>
                <div className='animate-spin'>
                  <AiOutlineLoading3Quarters size={18} />
                </div>
                <Text>Saving...</Text>
              </>
            ) : (
              <>
                <Text>Save</Text>
                <FrontArrowIcon />
              </>
            )}
          </Button>
        </Col>
      </Section>
    </Stacked>
  );
};

export default SaleOwner;
